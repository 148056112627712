.centered-container {
  margin-top:100px;
  text-align: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.notification-image {
  max-width:250px;
  margin-bottom: 20px;
}

h1 {
  margin: 10px 0;
  font-size: 15px;
  color: #333;
  font-weight: 500;
}

h2 {
  margin: 0;
  font-size: 12px;
  color: #666;
  font-weight: 500;

}